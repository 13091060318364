.wp-block-gco-coordinates {
  position: relative;

  address {
    font-style: normal;

    a, p {
      display: table;
      text-decoration: none !important;
      position: relative;
      padding-left: 30px;
      margin-bottom: .6rem;
      margin-top: 0;
    }

    i {
      position: absolute;
      left: 0;
      top: 0.15rem;
    }
  }
}
