/** Dans le back-office, lors que l'image n'est pas trouvée, le bloc est écrasé sur lui même, on ne peut pas modifier. **/
.wp-block-gco-carousel img {
  min-height: 100px;
}
.wp-block-gco-carousel.block-editor-block-list__block ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.block-editor-page .wp-block-gco-carousel li {
  width: 18.75rem;
}

.wp-block-gco-carousel {
  --width: 18.75rem;
  --height: 15rem;
  --images: 15rem;
  --image-height: 10rem;
  --image-object-fit: contain;
  --gap: 16px; /* ⚠️ bien laisser en pixel ! le javascript récupère 1 si 1rem au lieu de 16 pour 16px */
  --transition-opacity: 2s;
  --transition-translate: 1s;

  // Bulles
  --bulle-size: 1rem;
  --bulle-gap: .3rem;

  --width: 340px;
  --images: 1;

  @media (min-width: 768px) {
    --width: 549px;
    --images: 2;
  }

  @media (min-width: 1024px) {
    --width: 733px;
    --images: 2;
  }

  @media (min-width: 1280px) {
    --width: 917px;
    --images: 3;
  }

  @media (min-width: 1536px) {
    --width: 1380px;
    --images: 4;
  }
  position: relative;
  width: calc(var(--width) - var(--gap));
  margin: auto;

  .carousel {
    &.slide { 
      overflow: hidden;
      padding-bottom: .3rem;
      ul.inner-carousel {
        display: flex;
        width: max-content;
        margin: 0;
        transition: transform var(--transition-translate);
        li.img-item {
          margin-bottom: 0;
          --w: calc( var(--width) / var(--images) );
          width: var(--w);
          padding-right: var(--gap);
          figure {
            height: var(--height);
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              height: var(--image-height);
              object-fit: var(--image-object-fit);
            }
            &:last-of-type() {
              padding-right: 0;
            }
          }
          
        }
      } 
    }

    &.fade {
      ul.inner-carousel {
        display: grid;
        grid-template-columns: repeat(var(--images), 1fr);
        column-gap: var(--gap);
        margin: 0;
        li { 
          grid-row: 1/2;
          grid-column: 1/2;
          margin: 0;
          opacity: 0;
          transition: opacity var(--transition-opacity) ;
          @media (min-width: 768px) {
            &:nth-of-type(2n+1) { grid-column: 1/2; }
            &:nth-of-type(2n)   { grid-column: 2/3; }
          }
          @media (min-width: 1280px) {
            &:nth-of-type(3n+1) { grid-column: 1/2; }
            &:nth-of-type(3n+2) { grid-column: 2/3; }
            &:nth-of-type(3n)   { grid-column: 3/4; }
          }
          @media (min-width: 1536px) {
            &:nth-of-type(4n+1) { grid-column: 1/2; }
            &:nth-of-type(4n+2) { grid-column: 2/3; }
            &:nth-of-type(4n+3) { grid-column: 3/4; }
            &:nth-of-type(4n)   { grid-column: 4/5; }
          }
          figure {
            height: var(--height);
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              height: var(--image-height);
              object-fit: var(--image-object-fit);
            }
          }
          
        }
      }
    }

    &.bulle {
      div.radio-carousel {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: var(--bulle-gap);
        margin-bottom: .2rem;
        margin-top: 1rem;
        button {
          width: var(--bulle-size);
          height: var(--bulle-size);
          border-radius: 100%;
          border: 2px solid gray;

          @media (min-width:768px) and (max-width:1279px) {
            &:not(:nth-of-type(2n+1)) {
              display:none;
            }
          }
          @media (min-width:1280px) and (max-width:1535px) {
            &:not(:nth-of-type(3n+1)) {
              display: none;
            }
          }
          @media  (min-width: 1536px) {
            &:not(:nth-of-type(4n+1)) {
              display: none;
            }
          }
        }
      }
    }

    &.arrow {
      padding-bottom: 0;
      &:hover {
        .radio-carousel .fleches {
          opacity: .3;
          &:hover {
            opacity: .6;
          }
        }
      }
      .radio-carousel {
        .fleches {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 44px;
          background-color: black;
          color: white;
          text-align: center;
          opacity: 0;
        }
        .fleches:nth-of-type(1) {
          left: 0;
        }
        .fleches:nth-of-type(2) {
          right: 0;
          transform: rotate(180deg);
        }
      }
      
    }
  }

  figure {
    position: relative;    
    figcaption {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-shadow: 0px 3px 6px #00000080;
      margin: 0 !important;
      justify-content: center;
      align-items: center;
      /* move to theme ? */
      font-size: 2.5rem;
      font-style: normal;
    }
  }
  figure:hover figcaption {
    display: flex;
  }
}