
// wrapper section slider
.wrapper{
    --height-slider: 160px;
    --width-wrapper: 250px;
    --width-container: 250px;
    --margin-container: 0px;
    --nb-container: 1;

    @media(min-width: 1024px) { 
        --width-wrapper: 850px;
        --width-container: 250px;
        --margin-container: 25px;
        --nb-container: 3;
    }
    @media(min-width: 1536px) { 
        --width-wrapper: 1290px;
        --width-container: 210px;
        --margin-container: 40px; 
        --nb-container: 5;
    }

    padding: 0 1rem; 

    // Section du slider
    .section-slider { 
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: var(--height-slider);
        margin-left: auto;
        margin-right: auto;

        // bloc qui cache ce qui dépasse
        .wrapper-hidden{
            overflow: hidden;
            height: var(--height-slider);
            width: var(--width-wrapper);
            display: flex;
            justify-content: flex-start;
            position: inherit;
            order: 2;
            padding-left: var(--margin-container);

            // Bloc accueillant les containers sur une seule ligne et qui translatera 
            //lors des click sur les buttons
            .element-translate{
                display: flex;
                justify-content: flex-start;
                flex-direction: row;
                flex-wrap: nowrap;
                transition: 0.7s ease-out;
                height: var(--height-slider);
                // transform: translateX(calc(var(--datanum) * -1 * (var(--width) - 2rem - 96px)));
                transform: translateX(calc(var(--datanum) * -1 * var(--width-wrapper)));

                // Chaque containers
                > div {
                    margin-top: 0 !important;
                    width: var(--width-container);
                    min-width: var(--width-container);
                    margin-left: var(--margin-container);
                    display: inherit;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    padding: 0 48px !important;

                    &:last-child {
                        margin-right: var(--margin-container);
                    }

                    * {
                        max-width: var(--width-container);
                    }
                    figure {
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        // Buttons du slider
        button{

            transition: 0.1s;
            animation: isArrowVisible steps(var(--boolean)) forwards;
            visibility: hidden;
            cursor: pointer;
            color: gray;
            font-size: 18px;
            line-height: 24px;
            position: absolute;
            top: 100%;

            @media(min-width: 1024px) {
                font-size: 21px;
                line-height: 24px;
                position: relative;
                top: inherit;
            }

            // Button flèche gauche
            &:first-of-type{
                --boolean: min(1, max(var(--datanum), 0));
                // Step = 0,  Valeur par défault :
                order: 4;
                transform: translateX(-20px) rotate(180deg) translateY(-10px);

                @media(min-width: 1024px) {
                    margin-right: 40px;
                    order: 1;
                    transform: rotate(180deg);
                }
            }

            //Button flèche droite
            &:last-of-type{
                --boolean: min(1, max(((var(--nbElements) / var(--nb-container)) - 1 - var(--datanum)) , 0));
                order: 3;
                transform: translateX(20px) translateY(10px);

                @media(min-width: 1024px) {
                    margin-left: 40px;
                    font-size: 21px;
                    line-height: 24px;
                    transform: translateX(20px) translateY(0);
                }
            }

            &:focus {
                box-shadow: none;
                outline: 1px dotted gray;
            }
        }
    }
}

@keyframes isArrowVisible{
  to{
    // Step = 1:
    visibility: visible;
    cursor: pointer;
  }
}